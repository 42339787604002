import { axios } from '@/utils/request'

const api = {
    selectPages: 'charging/merchantBalance/select', // 查询商户余额
    getAll: 'charging/chargingpileFinance/getAll', // 查询商户余额分页
    selectAll: 'charging/chargingpileFinance/selectAll', // 查询体现记录分页
    selectMoney: 'charging/chargingpileIncomeMoney/getAll', // 查询对账单分页
    getDetails: 'charging/chargingpileIncomeMoney/getDetails', // 查询明细 收入  支出类型
    selectOrderDetails: 'charging/chargingpileIncomeMoney/selectOrderDetails', // 查询明细列表
    selectByLevel: 'charging/merchantBalance/selectByLevel', // 查询对账单金额数
    siteStatistics: 'charging/chargingpileIncomeMoney/siteStatistics', // 站点统计
    transferDetail: 'charging/wxpay/transferDetail', // 商户提现
}
export default api

// 查询商户余额
export const selectPagesApi = (params) => {
    return axios({
        url: api.selectPages,
        method: 'post',
        params
    })
}
// 查询商户余额分页
export const getAllApi = (params) => {
    return axios({
        url: api.getAll,
        method: 'post',
        params
    })
}
// 查询提现记录分页
export const selectAllApi = (data) => {
    return axios({
        url: api.selectAll,
        method: 'post',
        data
    })
}

// 查询对账单分页
export const selectMoneyApi = (params) => {
    return axios({
        url: api.selectMoney,
        method: 'post',
        params
    })
}

// 查询对账单分页
export const getDetailsApi = (params) => {
    return axios({
        url: api.getDetails,
        method: 'post',
        params
    })
}

export const selectOrderDetailsApi = (params) => {
    return axios({
        url: api.selectOrderDetails,
        method: 'post',
        params
    })
}

// 查询对账单金额数
export const selectByLevelApi = (params) => {
    return axios({
        url: api.selectByLevel,
        method: 'post',
        params
    })
}

// 站点统计
export const siteStatisticslApi = (params) => {
    return axios({
        url: api.siteStatistics,
        method: 'post',
        params
    })
}

// 商户提现
export const transferDetailApi = (params) => {
    return axios({
        url: api.transferDetail,
        method: 'post',
        params
    })
}