<!-- 对账单 -->
<template>
  <div class="warp">
    <!-- 账单列表 -->
    <el-card class="warp-content" v-if="statuTableData === 1">
      <!-- 描述列表区域 -->
      <el-descriptions
        v-if="userLevel === 0"
        class="descriptions-class"
        :title="descriptionTitle"
        :column="5"
        size="large"
        border
      >
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">平台总金额(合计)</div>
          </template>
          <el-tag size="small"
            >￥ {{ decimalClick(billMoney.sumCount) }}</el-tag
          >
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">微信支付总金额</div>
          </template>
          <el-tag type="success" size="small"
            >￥ {{ decimalClick(billMoney.wechatPayCount) }}</el-tag
          >
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">钱包充值总金额</div>
          </template>
          <el-tag type="success" size="small"
            >￥ {{ decimalClick(billMoney.weCharCount) }}</el-tag
          >
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">电卡充值总金额</div>
          </template>
          <el-tag type="success" size="small"
            >￥ {{ decimalClick(billMoney.cardCount) }}</el-tag
          >
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">赠送总金额</div>
          </template>
          <el-tag type="success" size="small"
            >￥ {{ decimalClick(billMoney.giveSum) }}</el-tag
          >
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">充值剩余总金额</div>
          </template>
          <el-tag size="small"
            >￥ {{ decimalClick(billMoney.surplusCount) }}</el-tag
          >
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">钱包充值剩余总额</div>
          </template>
          <el-tag size="small" type="success"
            >￥ {{ decimalClick(billMoney.presenterMoney) }}</el-tag
          >
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">电卡充值剩余总额</div>
          </template>
          <el-tag size="small" type="success"
            >￥ {{ decimalClick(billMoney.cardSurplusCount) }}</el-tag
          >
        </el-descriptions-item>
        <el-descriptions-item style="width: 199px">
          <template #label>
            <div class="cell-item">提现总金额</div>
          </template>
          <el-tag size="small"
            >￥ {{ decimalClick(billMoney.withdrawalMoney) }}</el-tag
          >
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        v-if="userLevel === 0"
        class="descriptions-class1"
        :title="descriptionTitle"
        :column="1"
        size="large"
        border
      >
        <el-descriptions-item style="width: 199px">
          <template #label>
            <div class="cell-item">消费总金额</div>
          </template>
          <el-tag size="small" type="success"
            >￥ {{ decimalClick(billMoney.consumptionMoney) }}</el-tag
          >
        </el-descriptions-item>
      </el-descriptions>
      <!-- 搜素区域 -->
      <div class="page-select">
        <el-row style="width: 100%; margin: 0 0 15px 0">
          <el-col :span="10">
            <span v-if="userLevel == 0" class="fontSize">代理商户：</span>
            <el-select
              class="search-input"
              placeholder="请选择代理商"
              size="small"
              v-model="merchantName"
              @change="hanchangeClick"
              v-if="userLevel == 0"
            >
              <el-option
                v-for="item in paymentTypeList"
                :key="item.merchantId"
                :label="item.merchantName"
                :value="item.merchantId"
              >
                {{ item.merchantName }}
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="14" style="text-align: right">
            <el-form
              label-width="100px"
              :model="formData"
              :rules="rules"
              ref="ruleForm"
              class="formClass"
            >
              <el-form-item label="时间范围：">
                <el-date-picker
                  v-model="alarTime"
                  unlink-panels
                  type="datetimerange"
                  size="small"
                  range-separator="至"
                  start-placeholder="起始时间"
                  end-placeholder="结束时间"
                  format="YYYY-MM-DD HH:mm:ss"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  style="margin-top: 6px"
                  @change="timeSearch"
                />
              </el-form-item>
            </el-form>
            <el-button type="primary" size="small" @click="searchClick"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </div>
      <!-- 表格区域 -->
      <div class="page-container">
        <el-table
          ref="tableRef"
          :data="tableData"
          style="width: 100%"
          max-height="630px"
          :header-cell-style="{ background: '#F2F4F8' }"
          @selection-change="handleSelectionChange"
        >
          <!--          <el-table-column type="selection" align="center" width="80" />-->
          <el-table-column
            v-for="(item, index) in tableColumnData"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            align="center"
          >
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template #default="{ row }">
              <el-button type="text" @click="detailedClick(row)"
                >明细</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页区域 -->
      <div class="page-foot">
        <page-nation
          :total="total"
          @pageChange="handleSizeChange"
          @currentChange="handleCurrentChange"
        ></page-nation>
      </div>
    </el-card>

    <!-- 明细 -->
    <div v-else>
      <el-row>
        <el-col :span="24">
          <el-card class="warp-content1">
            <span>财务汇总详情</span>
            <el-button
              class="butRight"
              type="primary"
              size="small"
              @click="uponeClick"
              >返回</el-button
            >
            <el-descriptions
              class="descriptions-class"
              :title="descriptionTitle"
              :column="2"
              size="large"
              border
            >
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">账户名称</div>
                </template>
                <el-tag type="success" size="small">{{
                  billDetailsForm.merchantName
                }}</el-tag>
              </el-descriptions-item>
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">账单周期</div>
                </template>
                <span>{{ billDetailsForm.connectTime }}</span>
              </el-descriptions-item>
            </el-descriptions>
          </el-card>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-card class="warp-content2">
            <el-descriptions
              class="descriptions-class"
              :title="descriptionTitle"
              :column="4"
              size="large"
              border
            >
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">上期结余</div>
                </template>
                <span>{{ billDetailsForm.startPeriodsBalance }}</span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">本期收入</div>
                </template>
                <span>{{ billDetailsForm.incomeBalance }}</span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">本期支出</div>
                </template>
                <span>{{ billDetailsForm.expenditureBalance }}</span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">本期结余</div>
                </template>
                <span>{{ billDetailsForm.endPeriodsBalance }}</span>
              </el-descriptions-item>
            </el-descriptions>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-card class="warp-content3">
            <span>收入类型</span>
            <span class="srRight"
              >合计：{{ srSumNums }}笔, {{ srSumBalance }}元</span
            >
            <el-table
              class="tableClass"
              :data="incomeTypeTableData"
              style="width: 100%"
              max-height="130px"
              :header-cell-style="{ background: '#F2F4F8' }"
            >
              <el-table-column
                v-for="(item, index) in incomeTypeTable"
                :key="index"
                :prop="item.prop"
                :label="item.label"
                align="center"
              >
                <template #default="{ row }" v-if="item.prop === 'status'">
                  <span>{{ row }}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card class="warp-content3">
            <span>支出类型</span>
            <span class="titRight"
              >合计：{{ zcSumNums }}笔, {{ zcSumBalance }}元</span
            >
            <el-table
              class="tableClass"
              :data="expenditureTypeTableData"
              style="width: 100%"
              max-height="130px"
              :header-cell-style="{ background: '#F2F4F8' }"
            >
              <el-table-column
                v-for="(item, index) in expenditureTypeTable"
                :key="index"
                :prop="item.prop"
                :label="item.label"
                align="center"
              >
                <template #default="{ row }" v-if="item.prop === 'status'">
                  <span>{{ row }}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-col>
      </el-row>
      <!-- <el-row :gutter="20">
        <el-col :span="12">
          <el-card class="warp-content4">
            <span>充电站收入</span>
            <el-table
              class="tableClass"
              :data="tableData"
              style="width: 100%"
              max-height="230px"
              :header-cell-style="{ background: '#F2F4F8' }"
            >
              <el-table-column
                v-for="(item, index) in networIncomekTable"
                :key="index"
                :prop="item.prop"
                :label="item.label"
                align="center"
              >
                <template #default="{ row }" v-if="item.prop === 'status'">
                  <span>{{ row }}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card class="warp-content4">
            <span>充电站支出</span>
            <el-table
              class="tableClass"
              :data="tableData"
              style="width: 100%"
              max-height="230px"
              :header-cell-style="{ background: '#F2F4F8' }"
            >
              <el-table-column
                v-for="(item, index) in networExpenditureTable"
                :key="index"
                :prop="item.prop"
                :label="item.label"
                align="center"
              >
                <template #default="{ row }" v-if="item.prop === 'status'">
                  <span>{{ row }}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-col>
      </el-row> -->
      <el-row>
        <el-col :span="24">
          <el-card class="warp-content5">
            <!-- <span>账单明细</span> -->
            <el-menu
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
              @select="handleSelect"
            >
              <el-menu-item index="income">收入账单明细</el-menu-item>
              <el-menu-item index="expenditure">支出账单明细</el-menu-item>
              <el-menu-item index="siteStatistics">站点收入统计</el-menu-item>
            </el-menu>
            <!-- 收入账单明细 -->
            <div v-if="activeIndex === 'income'">
              <!-- <span style="font-size: 14px">充电站：</span>
              <el-select
                class="search-input"
                placeholder="请选择充电站"
                size="small"
                v-model="selectValue"
                @change="hanchangeClicks"
              >
                <el-option
                  v-for="item in selectOptions"
                  :key="item.stationId"
                  :label="item.stationName"
                  :value="item.stationId"
                >
                  {{ item.stationName }}
                </el-option>
              </el-select> -->
              <el-table
                class="tableClass"
                :data="billDetailsTableData"
                style="width: 100%"
                max-height="250px"
                :header-cell-style="{ background: '#F2F4F8' }"
              >
                <el-table-column
                  v-for="(item, index) in billDetailsTable"
                  :key="index"
                  :prop="item.prop"
                  :label="item.label"
                  align="center"
                >
                  <template #default="{ row }" v-if="item.prop === 'status'">
                    <span>{{ row }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 收入账单明细 -->
            <div v-if="activeIndex === 'expenditure'">
              <el-table
                class="tableClass"
                :data="billDetailsTableData"
                style="width: 100%"
                max-height="250px"
                :header-cell-style="{ background: '#F2F4F8' }"
              >
                <el-table-column
                  v-for="(item, index) in billDetailsTable"
                  :key="index"
                  :prop="item.prop"
                  :label="item.label"
                  align="center"
                >
                  <template #default="{ row }" v-if="item.prop === 'status'">
                    <span>{{ row }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 站点统计 -->
            <div v-if="activeIndex === 'siteStatistics'">
              <el-table
                class="tableClass"
                :data="siteTableData"
                style="width: 100%"
                max-height="250px"
                :header-cell-style="{ background: '#F2F4F8' }"
              >
                <el-table-column
                  v-for="(item, index) in siteTable"
                  :key="index"
                  :prop="item.prop"
                  :label="item.label"
                  align="center"
                >
                </el-table-column>
              </el-table>
            </div>
            <!-- 分页区域 -->
            <div class="page-foot">
              <page-nation
                :total="billDetailsTotal"
                @pageChange="handleBillDetailsSizeChange"
                @currentChange="handleBillDetailsCurrentChange"
              ></page-nation>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue-demi";
import pageNation from "@/components/base/pageNation.vue";
import {
  selectMoneyApi,
  getDetailsApi,
  getAllApi,
  selectOrderDetailsApi,
  selectByLevelApi,
  siteStatisticslApi,
} from "@/api/financeManagement/financeManagement.js";
import { selectListApi } from "@/api/manageStatement/manageStatement.js";
import { getByMerchantIdApi } from "@/api/synthesisAnalysis/deviceAnalysis";
import { requestApi } from "@/utils/requestApi";
import { debounces } from "@/common/utils";
export default {
  name: "AccountSearch",
  components: { pageNation },
  setup() {
    const { proxy } = getCurrentInstance();
    const data = reactive({
      statuTableData: 1, //账单表与明细表的区分
      alarTime: [], //起始时间
      tableData: [], // 表格数据
      total: 0,
      pageSize: 10,
      currentPage: 1,
      userInfo: null, // 存储商户信息的
      userLevel: 0, //用户等级
      selection: [],
      disabled: true,
      // 详情表单
      billDetailsForm: {
        merchantName: "", // 账户名称
        connectTime: "", // 周期时间
        startPeriodsBalance: null, //本期期初
        endPeriodsBalance: null, // 本期结余
        incomeBalance: null, // 收入
        expenditureBalance: null, // 支出
      },
      // 账单金额
      billMoney: {
        wechatPayCount: 0, // 微信支付总金额
        weCharCount: 0, // 钱包充值总金额
        cardCount: 0, // 电卡充值总金额
        surplusCount: 0, // 剩余充值总金额
        giveSum: 0, // 赠送总金额
        sumCount: 0, // 平台总金额
        cardSurplusCount: 0, // 电卡充值剩余总额
        presenterMoney: 0, // 钱包充值剩余总额
        consumptionMoney:0,//  消费总金额
        withdrawalMoney:0,// 提现总金额
      },
      srSumNums: 0, // 收入总笔数
      srSumBalance: 0, // 支收入总金额
      zcSumNums: 0, // 支出总笔数
      zcSumBalance: 0, // 支出总金额

      incomeEndTime: null, // 结束时间
      tableColumnData: [
        {
          prop: "merchantName",
          label: "商户名称",
        },
        {
          prop: "dateTime",
          label: "日期",
        },
        {
          prop: "beginTime",
          label: "开始时间",
        },
        {
          prop: "endTime",
          label: "结束时间",
        },
        {
          prop: "incomeBalance",
          label: "收入(元)",
        },
        {
          prop: "expenditureBalance",
          label: "支出(元)",
        },
        {
          prop: "startPeriodsBalance",
          label: "上期结余(元)",
        },
        {
          prop: "endPeriodsBalance",
          label: "本期结余(元)",
        },
      ],
      // 收入类型表头
      incomeTypeTable: [
        {
          prop: "type",
          label: "类型",
        },
        {
          prop: "money",
          label: "金额",
        },
        {
          prop: "nums",
          label: "笔数",
        },
      ],
      incomeTypeTableData: [], // 收入类型数据
      // 支出类型表头
      expenditureTypeTable: [
        {
          prop: "type",
          label: "类型",
        },
        {
          prop: "money",
          label: "金额",
        },
        {
          prop: "nums",
          label: "笔数",
        },
      ],
      expenditureTypeTableData: [], // 支出类型数据
      // 充电站收入表头
      networIncomekTable: [
        {
          prop: "merchantName",
          label: "充电站名称",
        },
        {
          prop: "dateTime",
          label: "金额",
        },
        {
          prop: "beginTime",
          label: "笔数",
        },
      ],
      networIncomekTableData: [], //充电站收入数据
      // 充电站支出表头
      networExpenditureTable: [
        {
          prop: "merchantName",
          label: "充电站名称",
        },
        {
          prop: "dateTime",
          label: "金额",
        },
        {
          prop: "beginTime",
          label: "笔数",
        },
      ],
      networExpenditureTableData: [], //充电站支出数据
      // 账单明细表头
      billDetailsTable: [
        {
          prop: "merchantName",
          label: "账户",
        },
        {
          prop: "busType",
          label: "业务类型",
        },
        {
          prop: "stationName",
          label: "交易充电站",
        },
        {
          prop: "tradeNo",
          label: "业务单号",
        },
        {
          prop: "money",
          label: "金额(元)",
        },
        // {
        //   prop: "beforeMoney",
        //   label: "交易前余额(元)",
        // },
        // {
        //   prop: "endMoney",
        //   label: "交易后余额(元)",
        // },
        // {
        //   prop: "reName",
        //   label: "交易对手",
        // },
        {
          prop: "busTime",
          label: "入账时间",
        },
      ],
      billDetailsTableData: [], //对账单数据
      // 站点统计
      siteTable: [
        {
          prop: "stationName",
          label: "充电站名称",
        },
        {
          prop: "money",
          label: "金额",
        },
        {
          prop: "sums",
          label: "笔数",
        },
      ],
      siteTableData:[],// 站点统计
      billDetailsTotal: 0,
      billDetailsPageSize: 10,
      billDetailsCurrentPage: 1,
      paymentTypeList: [], //商户列表
      merchantName: null, // 商户绑定
      merchantId: null, // 选中的商户id
      activeIndex: "income", // 收入账单明细
      selectValue: null, //搜索站点
      selectOptions: [],
    });
    onMounted(() => {
      proxy.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      proxy.$nextTick(() => {
        proxy.userLevel = proxy.userInfo.chargingMerchant.level;
      });
      if (proxy.userLevel == 0) {
        methods.selectLevel();
        methods.selectList();
      } else {
        proxy.merchantId = proxy.userInfo.merchantId;
        methods.selectPages();
      }
    });
    // 节流搜索按钮
    const searchInput = debounces(() => {
      methods.selectPages();
    }, 500);
    const methods = {
      // 时间搜索
      timeSearch(row) {
        if (row != null) {
          methods.selectPages();
        } else {
          proxy.alarTime = [];
          methods.selectPages();
        }
      },
      // 搜索按钮
      searchClick() {
        methods.selectPages();
      },
      // 表格分页 改变每页数量时触发
      handleSizeChange(val) {
        proxy.pageSize = val;
        methods.selectPages();
      },
      // 表格分页 改变页数时触发
      handleCurrentChange(val) {
        proxy.currentPage = val;
        methods.selectPages();
      },
      // 查询对账表
      async selectPages() {
        await requestApi(
          () => {
            if (data.alarTime.length > 0) {
              const params = {
                current: data.currentPage,
                size: data.pageSize,
                merchantId: data.merchantId,
                startTime: data.alarTime[0],
                endTime: data.alarTime[1],
              };
              return selectMoneyApi(params);
            } else {
              const params = {
                current: data.currentPage,
                size: data.pageSize,
                merchantId: data.merchantId,
              };
              return selectMoneyApi(params);
            }
          },
          (res) => {
            if (res.code === 200) {
              proxy.tableData = res.data.records.map((it) => {
                return {
                  merchantName: it.merchantName, // 商户名称
                  dateTime: it.dateTime, // 当前时间
                  beginTime: it.beginTime, // 开始时间
                  endTime: it.endTime, // 结束时间
                  expenditureBalance: it.expenditureBalance, // 支出金额
                  incomeBalance: it.incomeBalance, // 收入金额
                  startPeriodsBalance: it.startPeriodsBalance, // 本期初期金额
                  endPeriodsBalance: it.endPeriodsBalance, // 本期结余金额
                };
              });
              data.total = res.data.total;
              data.pageSize = res.data.size;
              data.currentPage = res.data.current;
            }
          }
        );
      },

      // 查询 收入 类型
      async getDetails() {
        await requestApi(
          () => {
            const params = {
              merchantId: data.userInfo.merchantId,
              incomeType: 0, // 0 收入 1 支出
              todayTime: proxy.incomeEndTime,
            };
            return getDetailsApi(params);
          },
          (res) => {
            if (res.code === 200) {
              if (res.data.length > 0) {
                proxy.incomeTypeTableData = res.data[0].nums;
                proxy.srSumNums = res.data[0].sumNums;
                proxy.srSumBalance = res.data[0].sumBalance;
              }
            }
          }
        );
      },
      // 查询 支出 类型
      async getDetailss() {
        await requestApi(
          () => {
            const params = {
              merchantId: data.userInfo.merchantId,
              incomeType: 1, // 0 收入 1 支出
              todayTime: proxy.incomeEndTime,
            };
            return getDetailsApi(params);
          },
          (res) => {
            if (res.code === 200) {
              if (res.data.length > 0) {
                proxy.expenditureTypeTableData = res.data[0].nums;
                proxy.zcSumNums = res.data[0].sumNums;
                proxy.zcSumBalance = res.data[0].sumBalance;
              }
            }
          }
        );
      },
      // 点击明细
      detailedClick(val) {
        proxy.statuTableData = 2;
        proxy.incomeEndTime = val.endTime;
        proxy.billDetailsForm.merchantName = val.merchantName;
        proxy.billDetailsForm.connectTime = val.beginTime + "-" + val.endTime;
        proxy.billDetailsForm.startPeriodsBalance = methods.decimalClick(
          val.startPeriodsBalance
        );
        proxy.billDetailsForm.endPeriodsBalance = methods.decimalClick(
          val.endPeriodsBalance
        );
        proxy.billDetailsForm.incomeBalance = methods.decimalClick(
          val.incomeBalance
        );
        proxy.billDetailsForm.expenditureBalance = methods.decimalClick(
          val.expenditureBalance
        );

        methods.getDetails(); // 查询收入类型
        methods.getDetailss(); // 查询收入类型
        methods.getByMerchantId(); // 查询充电站
        methods.selectOrderDetails(); // 查询收入、支出账单明细
      },
      // 返回对账单表格
      uponeClick() {
        proxy.statuTableData = 1;
      },
      // 账单明细查询
      async selectPagesAll() {
        await requestApi(
          () => {
            const params = {
              current: data.currentPage,
              size: data.pageSize,
              merchantId: data.userInfo.merchantId,
            };
            return getAllApi(params);
          },
          (res) => {
            if (res.code === 200) {
              data.billDetailsTableData = res.data.records;
            }
          }
        );
      },
      // 查询账单明细
      async selectOrderDetails() {
        await requestApi(
          () => {
            if (proxy.activeIndex == "income") {
              const params = {
                current: data.currentPage,
                size: data.pageSize,
                merchantId: data.userInfo.merchantId, // 商户id
                todayTime: proxy.incomeEndTime, // 结束时间
                incomeType: 0, // 0 代表收入账单明细 1 代表支出账单明细
                stationId: proxy.selectValue, // 充电站
              };
              return selectOrderDetailsApi(params);
            } else {
              const params = {
                current: data.currentPage,
                size: data.pageSize,
                merchantId: data.userInfo.merchantId, // 商户id
                todayTime: proxy.incomeEndTime, // 结束时间
                incomeType: 1, // 0 代表收入账单明细 1 代表支出账单明细
                stationId: proxy.selectValue, // 充电站
              };
              return selectOrderDetailsApi(params);
            }
          },
          (res) => {
            if (res.code === 200) {
              data.billDetailsTableData = res.data.records;
              data.billDetailsTotal = res.data.total;
              data.billDetailsPageSize = res.data.size;
              data.billDetailsCurrentPage = res.data.current;
            }
          }
        );
      },
      // 查询对账单金额数
      async selectLevel() {
        await requestApi(
          () => {
            return selectByLevelApi();
          },
          (res) => {
            if (res.code === 200) {
              data.billMoney.wechatPayCount = res.data.wechatPayCount;
              data.billMoney.weCharCount = res.data.weCharCount;
              data.billMoney.cardCount = res.data.cardCount;
              data.billMoney.surplusCount = res.data.surplusCount;
              data.billMoney.giveSum = res.data.giveSum;
              data.billMoney.sumCount = res.data.sumCount;
              data.billMoney.cardSurplusCount = res.data.cardSurplusCount;
              data.billMoney.presenterMoney = res.data.presenterMoney;
              data.billMoney.consumptionMoney = res.data.consumptionMoney;
              data.billMoney.withdrawalMoney = res.data.withdrawalMoney;
            }
          }
        );
      },
      // 表格分页 改变每页数量时触发
      handleBillDetailsSizeChange(val) {
        proxy.billDetailsPageSize = val;
        methods.selectOrderDetails();
      },
      // 表格分页 改变页数时触发
      handleBillDetailsCurrentChange(val) {
        proxy.billDetailsCurrentPage = val;
        methods.selectOrderDetails();
      },
      // 保留两位小数 超过两位小数的测不变
      decimalClick(num) {
        if (!isNaN(num)) {
          if ((num + "").indexOf(".") != -1) {
            // 判断是否是小数
            if (num.toString().split(".")[1].length < 2) {
              return num.toFixed(2); //判断只有一位小数的自动补全
            } else {
              return num;
            }
          } else {
            return num.toFixed(2);
          }
        }
      },
      // 下拉点击事件查询代理商
      hanchangeClick(val) {
        proxy.merchantId = val;
        proxy.userInfo.merchantId = val;
        methods.selectPages();
      },
      // 查询商户列表
      async selectList() {
        await requestApi(
          () => {
            const params = {
              merchantId: proxy.userInfo.merchantId,
            };
            return selectListApi(params);
          },
          (res) => {
            if (res.code === 200) {
              proxy.paymentTypeList = res.data;
              if (proxy.paymentTypeList.length > 0) {
                proxy.merchantName = proxy.paymentTypeList[0].merchantId;
                proxy.merchantId = proxy.paymentTypeList[0].merchantId;
                methods.selectPages();
              }
            }
          }
        );
      },
      // 激活菜单的回调
      handleSelect(key) {
        proxy.selectValue = null;
        proxy.activeIndex = key;
        if (proxy.activeIndex == "siteStatistics") {
          methods.siteStatisticst();
        } else  {
          methods.selectOrderDetails();
        }
      },
      // 查询充电站
      getByMerchantId() {
        requestApi(
          () => {
            return getByMerchantIdApi({ merchantId: data.userInfo.merchantId });
          },
          (res) => {
            data.selectOptions = res.data;
          }
        );
      },
      // 下拉点击事件查询充电站账单明细
      hanchangeClicks(val) {
        proxy.selectValue = val;
        methods.selectOrderDetails();
      },
      // 站点统计查询
      async siteStatisticst() {
        await requestApi(
          () => {
            const params = {
              current: data.currentPage,
              size: data.pageSize,
              merchantId: data.userInfo.merchantId, // 商户id
              todayTime: proxy.incomeEndTime, // 结束时间
            };
            return siteStatisticslApi(params);
          },
          (res) => {
            if (res.code === 200) {
                data.siteTableData = res.data.records;
            }
          }
        );
      },
    };
    return {
      ...toRefs(data),
      searchInput,
      ...methods,
    };
  },
};
</script>
<style lang="less" scoped>
.warp {
  height: 100%;
  overflow-y: auto !important;
  overflow-x: hidden;
  background: #ffffff;
  .warp-content {
    height: 100%;
  }
  .warp-content1 {
    height: 13vh;
    .butRight {
      position: absolute;
      right: 20px;
    }
    span {
      font-size: 16px;
      font-weight: bold;
    }
    // .descriptions-class {
    //   // margin-top: 15px;
    // }
  }
  .warp-content2 {
    margin-top: 10px;
    height: 8vh;
  }
  .warp-content3 {
    margin-top: 10px;
    height: 22vh;
    display: flex;
    span {
      font-size: 16px;
      font-weight: bold;
    }
  }
  // .warp-content4 {
  //   margin-top: 10px;
  //   height: 31vh;
  //   span {
  //     font-size: 16px;
  //     font-weight: bold;
  //   }
  // }
  .warp-content5 {
    margin-top: 10px;
    height: 42vh;
    span {
      font-size: 16px;
      font-weight: bold;
    }
  }
}
.page-select {
  width: 100%;
  margin: 0 auto;
  .formClass {
    display: inline-block;
    margin-right: 4px;
  }
  /deep/.el-form-item {
    margin-bottom: 0;
  }
}
.page-container {
  width: 100%;
  //margin-top: -28px;
}
.page-foot {
  width: 100%;
  margin-top: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .my-btn {
    height: 28px;
  }
}
.search-input {
  width: 202px;
  margin-right: 4px;
  margin-top: 6px;
}

.search-input-class {
  width: 100%;
}
/deep/.el-form-item__label {
  white-space: nowrap;
}
/deep/.el-form-item {
  width: 100%;
}

.srRight {
  font-size: 14px;
  color: rgb(64, 76, 241);
  float: right;
}
.titRight {
  position: absolute;
  right: 30px;
  font-size: 14px;
  color: rgb(64, 76, 241);
}
.tableClass {
  margin-top: 8px;
}
.fontSize {
  font-size: 14px;
}
.descriptions-class1 {
  /deep/.el-descriptions__label.el-descriptions__cell.is-bordered-label {
    width: 199px;
  }
}
.el-menu-demo {
  height: 40px;
  background: #ededed;
  /deep/ .el-menu-item {
    height: 40px;
    line-height: 40px;
    color: #0f0f0f;
  }
  /deep/ .el-sub-menu .el-menu {
    // 子菜单背景颜色
    border: none;
    background: #ffffff;
  }
  /deep/ li.el-menu-item.is-active {
    // 选中子菜单颜色
    color: #1523f6 !important;
    background: #ededed;
    border-bottom: 2px solid #1523f6;
  }
}
</style>
