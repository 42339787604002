import { axios } from '@/utils/request'

const api = {
    device: 'charging/analysis/device', // 设备分析
    deviceDay: 'charging/analysis/deviceDay' , //日统计详情
    getByMerchantId: 'charging/station/getByMerchantId', // 查询充电站

    org: 'charging/analysis/station', //充电站分析
    stationDay: 'charging/analysis/stationDay',// 充电站分析-日统计
    getAgency: 'charging/merchant/getAgency', //查询旗下代理商户信息
}
export default api

export const getDeviceApi = (params) => {
    return axios({
        url: api.device,
        method: 'post',
        params
    })
}

export const getOrgApi = (params) => {
    return axios({
        url: api.org,
        method: 'post',
        params
    })
}

export const deviceDayApi = (params) => {
    return axios({
        url: api.deviceDay,
        method: 'post',
        params
    })
}

export const stationDayApi = (params) => {
    return axios({
        url: api.stationDay,
        method: 'post',
        params
    })
}

export const getByMerchantIdApi = (params) => {
    return axios({
        url: api.getByMerchantId,
        method: 'post',
        params
    })
}

export const getAgencyApi = (params) => {
    return axios({
        url: api.getAgency,
        method: 'post',
        params
    })
}
